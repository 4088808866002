import React from 'react';
import {
    Routes as BrowserRoutes, Route
} from 'react-router-dom';

import PaymentAmount from './PaymentAmount';
import Result from './Result';
import ToS from './ToS';

import "../i18n.js";

const Routes = () => (
    <BrowserRoutes>
        <Route index element={<PaymentAmount />} />
        <Route path="success" element={<Result />} />
        <Route path="failure" element={<Result />} />
        <Route path="tos" element={<ToS />} />
        <Route path="*" element={<PaymentAmount />} />
    </BrowserRoutes>
);

export default Routes;
