import React, { useEffect } from 'react';
import { Grid, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

import betssonDonationBannerGr from '../assets/betssonDonationBannerGr.png';
import betssonDonationBannerEn from '../assets/betssonDonationBannerEn.png';
import okIcon from '../assets/ok.png';
import failureIcon from '../assets/failure.png';
import vivaLogo from '../assets/viva.png';
import isselLogo from '../assets/issel.png';
import betssonFoundationLogo from '../assets/betssonFoundation.png';
// import greekFlag from '../assets/greekFlag.png';
// import englishFlag from '../assets/englishFlag.png';

const useStyles = makeStyles({
    root: {
        flexDirection: 'column',
        background: 'white',
        minHeight: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    header: {
        justifyContent: 'center',
        // background: 'linear-gradient(to right, #b3babd 50%, #cfdbe1 50%)',
        background: '#b98522',
    },
    footer: {
        justifyContent: 'center',
        backgroundColor: '#d7dada',
        marginTop: 'auto',
    },
    mainContainer: {
        height: 'fit-content',
        background: '#1D1D1C',
    },
    donationTitle: {
        width: '100%',
        height: '25px',
    },
    mainContent: {
        background: 'white',
        height: 'calc(100% - 25px)',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootContainer: {
        flexDirection: 'column',
        position: 'relative',
    },
    info: {
        marginTop: '1em',
        textAlign: 'center',
    },
    infoTitle: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    divider: {
        marginTop: '10px',
        background: '#FF4500',
        height: '2px',
    },
    inputContainer: {
        fontSize: '1.5em',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    subContainer: {
        fontSize: '1.2em',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    donationButtonContainer: {
        marginTop: '35px',
    },
    donationButton: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '15px',
        backgroundColor: '#FF4500',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#BC3301',
        }
    },
    languages: {
        position: 'absolute',
        top: '-15px',
        right: '0px',
    },
});

// const lngs = {
// 	"el-GR": greekFlag,
// 	"en-GB": englishFlag,
// };

const banners = {
    "el-GR": betssonDonationBannerGr,
    "en-GB": betssonDonationBannerEn,
};

const icons = {
    success: okIcon,
    failure: failureIcon,
};

const Result = () => {
	const { t, i18n } = useTranslation();
    const classes = useStyles();
    const resultSuccess = window.location.href.includes("success") ? "success" : "failure";

    useEffect(() => {
        document.title = t("Document.title");
    }, [t]);
    
    return (
        <Grid container className={classes.root} m={1}>
            <Grid container className={classes.header}>
                <Grid id="main" item>
                    <img src={banners[i18n.language]} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                </Grid>
            </Grid>
            <Grid container className={classes.mainContainer}>
                <Grid item className={classes.donationTitle} style={{ backgroundColor: "#FF4500" }} />
                <Grid container className={classes.mainContent}>
                    <Grid id="main" container className={classes.rootContainer}>
                        <Grid item className={classes.info} style={{ marginTop: '1.3em', textAlign: 'center' }}>
                            {t("PaymentAmount.donationTitle1")}
                            <a href="https://www.iliahtida.org/gr" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', cursor: "pointer" }}>
                                {t("PaymentAmount.donationTitle2")}
                            </a>
                            {"."}
                        </Grid>
                        <Grid item className={classes.info}>
                            {t("PaymentAmount.donationTitle3")}
                        </Grid>
                        <Grid item className={classes.info}>
                            {t("PaymentAmount.donationTitle4")}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item className={classes.inputContainer}>
                            <img src={icons[resultSuccess]} alt="" style={{width: '60px', height: '100%', objectFit: 'contain'}} />
                            {t(`Result.${resultSuccess}Message1`)}
                        </Grid>
                        <Grid item className={classes.subContainer}>
                            {t(`Result.${resultSuccess}Message2`)}
                        </Grid>
                        <Grid item className={classes.donationButtonContainer}>
                            <Button 
                                variant="contained"
                                className={classes.donationButton}
                                fullWidth
                                href="/"
                            >
                                {t("Result.backToHome")}
                            </Button>
                        </Grid>
                        {/* <Grid item className={classes.languages}>
                            {Object.keys(lngs).map((lng) => (
                                <Button
                                    key={lng}
                                    onClick={() => {
                                        i18n.changeLanguage(lng);
                                        document.title = t("Document.title");
                                    }}
                                >
                                    <img src={lngs[lng]} alt={lng} style={{width: '100%', height: '100%', maxWidth: "30px", objectFit: 'contain'}} />
                                </Button>
                            ))}
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.footer} style={{ padding: '20px' }}>
                <Grid id="main" item style={{ width: "100%" }}>
                    <img src={vivaLogo} alt="Viva" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={betssonFoundationLogo} alt="Betsson Foundation" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={isselLogo} alt="Issel" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Result;
