import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'el-GR');
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
        order: ["localStorage"],
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage'],
		fallbackLng: "el-GR",
		resources: {
			// "en-GB": {
            //     translation: {
            //         Document: {
            //             title: "Betsson - We move together",
            //         },
			// 		PaymentAmount: {
			// 			donationAmount: "Donation Amount:",
            //             donationTitle1: "The Betsson Foundation and the Association of Business Women of Greece are working together to financially support the rehabilitation and medical treatment of little Marios who suffers from Ewing's Sarcoma in the knee.",
            //             donationTitle2: "You can also contribute to this project and show your solidarity. Every financial contribution can make a difference!",
            //             donate: "Donate",
            //             tos1: "I agree to the",
            //             tos2: "terms of service",
            //             description: "The Betsson Foundation and the Association of Business Women of Greece are working together to financially support the rehabilitation and medical treatment of little Marios who suffers from Ewing's Sarcoma in the knee.",
			// 		},
            //         Result: {
            //             successMessage1: "Transaction completed successfully!",
            //             successMessage2: "Thank you very much!",
            //             backToHome: "Back to Home",
            //             failureMessage1: "Hmm, something went wrong!",
            //             failureMessage2: "Please try again",
            //         },
			// 	},
			// },
			"el-GR": {
				translation: {
                    Document: {
                        title: "Betsson - Προχωράμε μαζί",
                    },
					PaymentAmount: {
						donationAmount: "Ποσό Δωρεάς:",
                        donationTitle1: "Το Betsson Foundation και ο ΟΦΗ ενώνουν τις δυνάμεις τους για την οικονομική ενίσχυση του παιδικού ξενώνα φιλοξενίας ",
                        donationTitle2: "«Ηλιαχτίδα»",
                        donationTitle3: "Βασικός στόχος της Ηλιαχτίδας είναι να καλύπτει οικονομικές ανάγκες και να παρέχει ψυχολογική υποστήριξη στα παιδιά με καρκίνο και στις οικογένειες τους.",
                        donationTitle4: "Μπορείς και εσύ να συνεισφέρεις σε αυτή την πρωτοβουλία! Η υποστήριξή σου μπορεί να φέρει ελπίδα και βελτίωση στις ζωές των παιδιών που δίνουν τη δική τους μάχη!",
                        donate: "Κάντε Δωρεά",
                        tos1: "Συμφωνώ με τους",
                        tos2: "όρους χρήσης",
                        description: "Το Betsson Foundation και ο ΟΦΗ ενώνουν τις δυνάμεις τους για την οικονομική ενίσχυση του παιδικού ξενώνα φιλοξενίας «Ηλιαχτίδα». Βασικός στόχος της Ηλιαχτίδας είναι να καλύπτει οικονομικές ανάγκες και να παρέχει ψυχολογική υποστήριξη στα παιδιά με καρκίνο και στις οικογένειες τους.",
					},
                    Result: {
                        successMessage1: "Η συναλλαγή ολοκληρώθηκε με επιτυχία!",
                        successMessage2: "Ευχαριστούμε πολύ!",
                        backToHome: "Πίσω στην Αρχική",
                        failureMessage1: "Χμμ, κάτι πήγε στραβά!",
                        failureMessage2: "Παρακαλώ δοκιμάστε ξανά",
                    },
				},
			},
		},
	});

export { default } from "i18next";
